import React, { useEffect, useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom";

import { ReactComponent as SearchIcon } from "../../assets/images/search.svg";
import { Link } from "react-router-dom";
import useFetch from "../../hooks/useFetch";

export default function Search(props) {
	const [activeCategories, setActiveCategories] = useState([0]);
	const [activeJobType, setActiveJobType] = useState(0);
	const [activeExperience, setActiveExperience] = useState(0);
	const [searchQuery, setSearchQuery] = useState(null);

	// Separate state for filter parameters
	const [filterParams, setFilterParams] = useState({
		categories: activeCategories,
		jobtype: activeJobType,
		experience: activeExperience,
	});

	const [searchParams, setSearchParams] = useSearchParams();
	const [filterQuery, setFilterQuery] = useState(
		"https://main.jobs3.io/wp-json/wp/v2/jobs"
	);

	useEffect(() => {
		// Update filter parameters
		setFilterParams({
			categories: activeCategories,
			jobtype: activeJobType,
			experience: activeExperience,
		});
	}, [activeCategories, activeJobType, activeExperience]);

	useEffect(() => {
		// Construct query using filter parameters
		const baseQuery = "https://main.jobs3.io/wp-json/wp/v2/jobs";

		let queryParams = "";

		if (filterParams.categories.length > 0) {
			queryParams += `&sector=${filterParams.categories}`;
		}

		if (filterParams.jobtype) {
			queryParams += `&jobtype=${filterParams.jobtype}`;
		}

		if (filterParams.experience) {
			queryParams += `&experience-level=${filterParams.experience}`;
		}

		// Remove the leading '&' if there are parameters
		if (queryParams.length > 0) {
			queryParams = "?" + queryParams.substring(1);
		}

		if (searchQuery) {
			if (queryParams.length > 0) queryParams += `&search=${searchQuery}`;
			else queryParams += `?search=${searchQuery}`;
		}

		setFilterQuery(baseQuery + queryParams);
	}, [filterParams]);

	console.log(filterQuery);
    
	const {
		loading: loadingJobs,
		error: errorJobs,
		data: dataJobs,
		count: jobsNumber,
	} = useFetch(filterQuery);

	if (dataJobs) {
		console.log("job url: ", dataJobs[0]?.jobsearch_field_job_apply_url);
	}

	const {
		loading: loadingSector,
		error: errorSector,
		data: dataSector,
	} = useFetch(`https://main.jobs3.io/wp-json/wp/v2/sector?per_page=12`);
	const {
		loading: loadingExperience,
		error: errorExperience,
		data: dataExperience,
	} = useFetch(
		`https://main.jobs3.io/wp-json/wp/v2/experience-level?per_page=12`
	);

	useEffect(() => {
		const categoriesString = searchParams.get("categories");
		setActiveCategories(
			categoriesString ? categoriesString.split("--") : []
		);

		setActiveExperience(searchParams.get("experience"));
		setActiveJobType(searchParams.get("jobtype"));
		setSearchQuery(searchParams.get("search"));
		if (props.search_text) {
			setSearchQuery(props.search_text);
		}
	}, [searchParams]);

	useEffect(() => {
		const handleDataLoad = () => {
			const elementsToCheck = document.querySelectorAll(
				"input[data-field-id]"
			);

			if (elementsToCheck.length > 0) {
				elementsToCheck.forEach((inputElement) => {
					const fieldId = inputElement.getAttribute("data-field-id");
				});
			}
		};
	}, [activeCategories, loadingJobs, loadingSector, loadingExperience]);

	const formatDateDifference = (date) => {
		const currentDate = new Date();
		const targetDate = new Date(date);

		const differenceInMilliseconds = currentDate - targetDate;
		const differenceInSeconds = differenceInMilliseconds / 1000;
		const differenceInMinutes = differenceInSeconds / 60;
		const differenceInHours = differenceInMinutes / 60;
		const differenceInDays = differenceInHours / 24;
		const differenceInMonths = differenceInDays / 30.44;
		const differenceInYears = differenceInDays / 365.25;

		if (differenceInDays < 1) {
			return `${Math.round(differenceInHours)} hours ago`;
		} else if (differenceInDays < 30) {
			return `${Math.round(differenceInDays)} days ago`;
		} else if (differenceInMonths < 12) {
			return `${Math.round(differenceInMonths)} months ago`;
		} else {
			return "More than one year ago";
		}
	};
	const job_img = (result) => {
		const ogImage = result.yoast_head_json?.og_image;
		if (ogImage && ogImage[0] && ogImage[0].url) {
			return ogImage[0].url;
		} else {
			return "https://thinkfirstcommunication.com/wp-content/uploads/2022/05/placeholder-1-1.png";
		}
	};
	const workingHours = (result) => {
		const returned_value = result.working_hours[0];
		console.log("working hours: ", result.working_hours);
		if (returned_value == "value1") {
			return "Full-time";
		} else if (returned_value == "value2") {
			return "Part-time";
		} else if (returned_value == "value3") {
			return "1-10 Hours a week";
		} else {
			return;
		}
	};
	const filterThis = () => {
		const filter_inputs = document.querySelectorAll(".filter_box input");
		const newActiveCategories = [];
		let newActiveJobType = null;
		let newActiveExperience = null;

		filter_inputs.forEach((single_filter_input) => {
			const isActiveKey = single_filter_input.checked;
			if (isActiveKey) {
				if (
					single_filter_input.getAttribute("data-filter-name") ===
					"category"
				) {
					const data_field_id =
						single_filter_input.getAttribute("data-field-id");
					newActiveCategories.push(data_field_id);
				}
				if (
					single_filter_input.getAttribute("data-filter-name") ===
					"working_style"
				) {
					let filter_input_val =
						single_filter_input.getAttribute("value");
					newActiveJobType = filter_input_val;
				}
				if (
					single_filter_input.getAttribute("data-filter-name") ===
					"experience"
				) {
					let filter_input_val =
						single_filter_input.getAttribute("value");
					newActiveExperience = filter_input_val;
				}
			}
		});

		// Create an object to hold non-null parameters
		const paramsToUpdate = {};

		// Update the object with the newActiveCategories, newActiveJobType, and newActiveExperience
		if (newActiveCategories.length > 0) {
			paramsToUpdate.categories = newActiveCategories
				.join("--")
				.toString();
		}
		if (newActiveJobType !== null) {
			paramsToUpdate.jobtype = newActiveJobType;
		}
		if (newActiveExperience !== null) {
			paramsToUpdate.experience = newActiveExperience;
		}
		if (searchQuery !== null) {
			paramsToUpdate.search = searchQuery;
		}

		// Update the URL with the non-null parameters
		setSearchParams(paramsToUpdate);
	};
	const handleSearch = (search_ev) => {
		search_ev.preventDefault();
		const search_input = document.querySelector("#search_input");
		setSearchQuery(search_input.value);
		setTimeout(() => {
			filterThis();
		}, 500);
	};
	const active_search = searchParams.get("search");

	return (
		<div className="search_page">
			<h2>{!searchQuery ? "Jobs" : searchQuery}</h2>
			<p>
				This is a classifieds job board and therefore not subject to DAO
				governance. Exercise caution when evaluating job posts
			</p>
			{console.log(props.search_text)}
			{props.search_text == undefined && (
				<form
					method="GET"
					onSubmit={(search_ev) => {
						handleSearch(search_ev);
					}}
				>
					<input
						type="text"
						placeholder="Search: Frontend developer, Marketing, Binance, etc."
						id="search_input"
						defaultValue={active_search}
					/>
					<SearchIcon />
				</form>
			)}
			<div className="search_tags">
				<div className="single_search"> All jobs </div>
				<div className="single_search"> Analyst (185) </div>
				<div className="single_search"> Backend Developer (116) </div>
				<div className="single_search">
					{" "}
					Blockchain Specialist (293){" "}
				</div>
				<div className="single_search">
					{" "}
					Business Development Manager (198){" "}
				</div>
				<div className="single_search">
					{" "}
					Compliance Specialist (112){" "}
				</div>
				<div className="single_search"> Crypto Specialist (260) </div>
				<div className="single_search"> Data (134) </div>
				<div className="single_search"> DeFi Expert (121) </div>
				<div className="single_search"> Designer (160) </div>
				<div className="single_search"> Engineer (803) </div>
				<div className="single_search"> Finance Expert (180) </div>
				<div className="single_search"> Show all categories </div>
			</div>
			<div className="search_results_filter_container">
				<div className="filter_search_results">
					<div className="filter_box">
						<div className="filter_title">Job categories</div>
						<div className="filter_options">
							{!loadingSector && !errorSector && dataSector
								? dataSector.map((single_sector) => {
										return (
											<div
												className="filter_option"
												key={single_sector.id}
											>
												<input
													type="checkbox"
													data-field-id={
														single_sector.id
													}
													onChange={() => {
														filterThis();
													}}
													id={`category_${single_sector.id}`}
													name="category"
													data-filter-name="category"
												/>
												<label
													htmlFor={`category_${single_sector.id}`}
												>
													{single_sector.name}
												</label>
											</div>
										);
								  })
								: Array.from({ length: 4 }).map((_, index) => (
										<div
											key={index}
											className="single_search_result is_loading"
										>
											<div className="loading_card"></div>
										</div>
								  ))}
						</div>
					</div>
					<div className="filter_box">
						<div className="filter_title">Working Style</div>
						<div className="filter_options">
							<div className="filter_option">
								<input
									type="radio"
									onChange={() => {
										filterThis();
									}}
									id={`working_style_inperson`}
									name="working_style"
									data-field-id={44}
									value={44}
									data-filter-name="working_style"
								/>
								<label htmlFor="working_style_inperson">
									In Person
								</label>
							</div>
							<div className="filter_option">
								<input
									type="radio"
									onChange={() => {
										filterThis();
									}}
									id={`working_style_remote`}
									name="working_style"
									data-field-id={40}
									value={40}
									data-filter-name="working_style"
								/>
								<label htmlFor="working_style_remote">
									Remote
								</label>
							</div>
						</div>
					</div>
					<div className="filter_box">
						<div className="filter_title">Experience</div>
						<div className="filter_options">
							{!loadingExperience &&
							!errorExperience &&
							dataExperience
								? dataExperience.map((single_experience) => {
										return (
											<div
												className="filter_option"
												key={single_experience.id}
											>
												<input
													type="radio"
													data-field-id={
														single_experience.id
													}
													onChange={() => {
														filterThis();
													}}
													id={`experience_${single_experience.slug}`}
													name="experience_field"
													value={single_experience.id}
													data-filter-name="experience"
												/>
												<label
													htmlFor={`experience_${single_experience.slug}`}
												>
													{single_experience.name}
												</label>
											</div>
										);
								  })
								: Array.from({ length: 4 }).map((_, index) => (
										<div
											key={index}
											className="single_search_result is_loading"
										>
											<div className="loading_card"></div>
										</div>
								  ))}
						</div>
					</div>
					<div className="filter_box">
						<div className="filter_title">Job Type</div>
						<div className="filter_options">
							<div className="filter_option">
								<input
									type="radio"
									id="job_type_fulltime"
									name="job_type"
								/>
								<label htmlFor="job_type_fulltime">
									Full-Time
								</label>
							</div>
							<div className="filter_option">
								<input
									type="radio"
									id="job_type_parttime"
									name="job_type"
								/>
								<label htmlFor="job_type_parttime">
									Part-Time
								</label>
							</div>
							<div className="filter_option">
								<input
									type="radio"
									id="job_type_contract"
									name="job_type"
								/>
								<label htmlFor="job_type_contract">
									Contract
								</label>
							</div>
							<div className="filter_option">
								<input
									type="radio"
									id="job_type_freelance"
									name="job_type"
								/>
								<label htmlFor="job_type_freelance">
									Freelance
								</label>
							</div>
							<div className="filter_option">
								<input
									type="radio"
									id="job_type_volunteer"
									name="job_type"
								/>
								<label htmlFor="job_type_volunteer">
									Volunteer
								</label>
							</div>
							<div className="filter_option">
								<input
									type="radio"
									id="job_type_internship"
									name="job_type"
								/>
								<label htmlFor="job_type_internship">
									Internship
								</label>
							</div>
						</div>
					</div>
					<div className="filter_box">
						<div className="filter_title">Pay</div>
						<div className="filter_options">
							<div className="filter_option">
								<input type="radio" id="pay_25000" name="pay" />
								<label htmlFor="pay_25000">$25,000+</label>
							</div>
							<div className="filter_option">
								<input type="radio" id="pay_35000" name="pay" />
								<label htmlFor="pay_35000">$35,000+</label>
							</div>
							<div className="filter_option">
								<input type="radio" id="pay_45000" name="pay" />
								<label htmlFor="pay_45000">$45,000+</label>
							</div>
							<div className="filter_option">
								<input type="radio" id="pay_55000" name="pay" />
								<label htmlFor="pay_55000">$55,000+</label>
							</div>
						</div>
					</div>
					<div className="filter_box">
						<div className="filter_title">Date Posted</div>
						<div className="filter_options">
							<div className="filter_option">
								<input
									type="radio"
									id="date_last3days"
									name="date_posted"
								/>
								<label htmlFor="date_last3days">
									Last 3 days
								</label>
							</div>
							<div className="filter_option">
								<input
									type="radio"
									id="date_last24hours"
									name="date_posted"
								/>
								<label htmlFor="date_last24hours">
									Last 24 hours
								</label>
							</div>
							<div className="filter_option">
								<input
									type="radio"
									id="date_last7days"
									name="date_posted"
								/>
								<label htmlFor="date_last7days">
									Last 7 days
								</label>
							</div>
							<div className="filter_option">
								<input
									type="radio"
									id="date_last2weeks"
									name="date_posted"
								/>
								<label htmlFor="date_last2weeks">
									Last 2 weeks
								</label>
							</div>
							<div className="filter_option">
								<input
									type="radio"
									id="date_lastmonth"
									name="date_posted"
								/>
								<label htmlFor="date_lastmonth">
									Last month
								</label>
							</div>
						</div>
					</div>
				</div>
				{!loadingJobs &&
				!errorJobs &&
				dataJobs &&
				Array.isArray(dataJobs) ? (
					<div className="all_search_results">
						<div className="search_results_con">
							<div className="search_results_top">
								<div className="jobs_found_num">
									{jobsNumber} Jobs Found
								</div>
								{jobsNumber == 0 && (
									<div className="sorry_message">
										<p>
											No Record Sorry! Does ot match
											record with your keyword. Change
											your filter keyword to re-submit OR{" "}
										</p>
										<div className="reset_filter">
											Reset Filters
										</div>
									</div>
								)}
								<div className="email_me_form">
									<div className="email_me_title">
										Email Me New Jobs
									</div>
									<form action="">
										<div className="form_top">
											<input
												type="text"
												placeholder="Job Alert Name..."
											/>
											<input
												type="email"
												placeholder="Email"
											/>
											<button type="submit">
												Create Alert
											</button>
										</div>
										<div className="form_checkboxes">
											<div className="single_check">
												<input
													type="checkbox"
													name="send_email"
													id="daily"
												/>
												<label htmlFor="daily">
													Daily
												</label>
											</div>
											<div className="single_check">
												<input
													type="checkbox"
													name="send_email"
													id="weekly"
												/>
												<label htmlFor="weekly">
													Weekly
												</label>
											</div>
											<div className="single_check">
												<input
													type="checkbox"
													name="send_email"
													id="fortnightly"
												/>
												<label htmlFor="fortnightly">
													Fortnightly
												</label>
											</div>
											<div className="single_check">
												<input
													type="checkbox"
													name="send_email"
													id="monthly"
												/>
												<label htmlFor="monthly">
													Monthly
												</label>
											</div>
										</div>
									</form>
								</div>
							</div>
							<div className="search_results_wrapper">
								{dataJobs.map((result) => (
									<div
										className="single_search_result"
										key={result.id}
									>
										<>
											<div className="search_result_img">
												<img
													src={job_img(result)}
													alt={`Job: ${result.title.rendered}`}
												/>
											</div>
											<div className="search_result_details">
												<small>
													{formatDateDifference(
														result.date
													)}{" "}
													| &nbsp;
													{
														result.acf.sl_job_views
													}{" "}
													views | &nbsp;
													{
														result.jobsearch_job_applicants_list.split(
															","
														).length
													}{" "}
													applications
												</small>
												{/* <p>Category: {result._yoast_wpseo_primary_sector}</p> */}
												{/* <p>Job Type: {result.jobtype.join(',')}</p> */}
												<h3>{result.title.rendered}</h3>
												<span>
													{result._gofj_company &&
														`at ${result._gofj_company}`}
												</span>
												<p>
													{workingHours(result) &&
														`Working Hours: ${workingHours(
															result
														)}`}
												</p>
											</div>
											<div className="search_result_features">
												{result.featured && (
													<div className="circle_featured">
														Featured
													</div>
												)}
												{result.remote && (
													<div className="circle_remote">
														Remote
													</div>
												)}
												{result.fullTime && (
													<div className="circle_fulltime">
														Full-time
													</div>
												)}
												<div className="circle_softwarengineer">
													{result.jobType}
												</div>
												<div className="circle_senorlevel">
													{result.level}
												</div>
											</div>
											<div className="buttons_container">
												{result &&
													result.jobsearch_field_job_apply_url && (
														<Link
															target="_blank"
															to={
																result.jobsearch_field_job_apply_url
															}
														>
															Apply
														</Link>
													)}
												<Link to="#">View Details</Link>
											</div>
										</>
									</div>
								))}
							</div>
						</div>
					</div>
				) : (
					<div className="all_search_results">
						<div className="search_results_con">
							<div className="search_results_wrapper">
								{Array.from({ length: 10 }).map((_, index) => (
									<div
										key={index}
										className="single_search_result is_loading"
									>
										<div className="loading_card"></div>
									</div>
								))}
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
