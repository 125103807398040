import React from 'react';
import { Link } from 'react-router-dom';

/*------------------| Images |------------------*/ 
import VerifiedIcon   from '../../assets/images/icons/verfified_orange.svg'; 
import Company_badge_orange from '../../assets/images/icons/orange_badge.svg';
import client_image from '../../assets/images/logos/employers/machinations.png';
import { ReactComponent as FacebookIcon } from '../../assets/images/social_media/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../assets/images/social_media/instagram.svg';
import { ReactComponent as TwitterIcon } from '../../assets/images/social_media/twitter.svg';


export default function Machinations() {  
    return (
        <div className="employer_page">
            <div className="employer_container">
                <div className="employer_top">
                    <div className="employer_img"> 
                        <img src={client_image} />
                    </div>
                    <div className="employer_details">
                        <div className="employer_name">
                            <img src={Company_badge_orange} /> <h2>Machinations</h2> <img src={VerifiedIcon} />
                        </div>
                        <div className="employer_links">
                            <a href="https://machinations.io/">https://machinations.io</a>
                            <div className="social_links">
                                <a href="#"><FacebookIcon /></a>
                                <a href="#"><InstagramIcon /></a>
                                <a href="#"><TwitterIcon /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="employer_bottom">
                    <div className="employer_left"> 
                        <div className="employer_summary_stats">
                            <div className="single_summary">
                                <strong>01/01/2016</strong> <span>Founded Date</span>
                            </div>
                            <div className="single_summary">
                                <strong>0</strong> <span>Posted Jobs</span>
                            </div>
                        </div> 
                        <div className="title_with_links">
                            <h3>Company Sectors</h3>
                            <div className="company_links_container">
                                <a href="#">Game Designer</a>
                                <a href="#">Blockchain</a>
                            </div>
                        </div>
                        <div className="title_with_links">
                            <h3>HQ Location</h3>
                            <div className="company_links_container">
                                <a href="#">United Kingdom</a>
                            </div>
                        </div>
                    </div>
                    <div className="employer_right">
                        <div className="company_description">
                            <h3>Company Description</h3>
                            <h2>Company Description</h2>
                            <p>Changing the way we do game design. We truly believe in creating a platform where game data lives and breathes as a unit. A platform interconnected with your existing game dev tool stack, so that game balancing happens naturally. A seamless flow between creators, diagrams, game engine and player behaviour.</p>

                            <h2>Story</h2>
                            <p>In 2016 we looked for a holistic, scientific alternative to gut-feeling based decisions and Sisyphean iterations when doing game balancing. A Google search returned Joris Dormans' Ph.D. paper and the tool he specifically created for balancing game economies: Machinations. Using the framework and tool game designers could map, simulate, and optimise complex systems.</p>
                            <p>Mihai started using it in his work and presented it on stage at conferences and workshops throughout Denmark and Sweden. Unsurprisingly, he found a powerful community of professionals around Machinations. Apparently his issues with outdated DDs and endless-scrolling spreadsheets were ubiquitous: from indie studios to AAA.</p>
                            <p>Machinations had the potential to become the game design tool, the one to declutter the balancing process. It would equip everyone involved in game development with a standardised language and a powerful instrument to validate their assumptions, with minimal effort.</p>
                            <p>Mihai and Dana flew over to Amsterdam, met Joris, and decided to take Machinations to the next level: becoming a part of the tech stack of game dev studios around the world, changing the way game systems are designed, prototyped and handed-off within teams. All the while supporting academia in teaching entire generations of game designers & developers to "speak" Machinations.</p>

                            <h2>Careers at Machinations</h2>
                            <p>We're a fast-growing product-led company in an exciting, innovative industry that's bigger than the movie e music industries combined. You will be part of an international, semi-remote, ambitious, growth-focused team.</p>

                            <h2>Team</h2>
                            <p>We're a product-driven team, working remotely from 7 global cities: Portsmouth, Cairo, Brasov, Cluj, Bucharest, Stockholm and Luxembourg.</p>

                        </div>
                        <div className="work_here">
                            <h2>I wanna work here</h2>
                            <form action="">
                                {/*-------| I think this better be a <select> but not sure which options should it have |------*/}
                                <input type="text"  name='position' placeholder='Position'/>
                                <input type="email" name="email" placeholder='Email' />
                                <button type="submit">Send Request</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ready_to_work">
                <h2>Ready to work with Metamask?</h2>
                <a href="#" className="sign_up_btn">Sign Up</a>
                <p>Already have an account? <a href="#">Log in</a></p>
            </div>
        </div>
    )
}