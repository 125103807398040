import React from 'react';
import { Link } from 'react-router-dom';

/*------------------| Images |------------------*/
import ProfileImage   from '../../assets/images/logos/employers/sentry.png'; 
import VerifiedIcon   from '../../assets/images/icons/verfified_orange.svg'; 
import Company_badge_orange from '../../assets/images/icons/orange_badge.svg';
import client_image from '../../assets/images/logos/employers/sentry.png'; 
import { ReactComponent as FacebookIcon } from '../../assets/images/social_media/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../assets/images/social_media/instagram.svg';
import { ReactComponent as TwitterIcon } from '../../assets/images/social_media/twitter.svg';


export default function Sentry() {  
    return (
        <div className="employer_page">
            <div className="employer_container">
                <div className="employer_top">
                    <div className="employer_img"> 
                        <img src={client_image} />
                    </div>
                    <div className="employer_details">
                        <div className="employer_name">
                            <img src={Company_badge_orange} /> <h2>Sentry</h2> <img src={VerifiedIcon} />
                        </div>
                        <div className="employer_links">
                            <a href="http://sentry.com">http://sentry.com</a>
                            <div className="social_links">
                                <a href="#"><FacebookIcon /></a>
                                <a href="#"><InstagramIcon /></a>
                                <a href="#"><TwitterIcon /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="employer_bottom">
                    <div className="employer_left"> 
                        <div className="employer_summary_stats">
                            <div className="single_summary">
                                <strong>01/01/1900</strong> <span>Founded Date</span>
                            </div>
                            <div className="single_summary">
                                <strong>0</strong> <span>Posted Jobs</span>
                            </div>
                        </div> 
                        <div className="title_with_links">
                            <h3>Company Sectors</h3>
                            <div className="company_links_container">
                                <a href="#">Consumer App</a>
                            </div>
                        </div>
                        <div className="title_with_links">
                            <h3>HQ Location</h3>
                            <div className="company_links_container">
                                <a href="#">United Kingdom</a>
                            </div>
                        </div>
                    </div>
                    <div className="employer_right">
                        <div className="company_description">
                            <h2>Company Description</h2>
                            <h1>We’ve been building powerful relationships since 1904</h1>
                            <p> 
                                If you’re like us, you don’t want to do business with a faceless institution. You want to know what motivates the people who move a business. You want to know who we are, and what we’re all about. We get that, because it’s how we do business too. We work to get to know our customers and their businesses. Why? Because it helps us provide the best possible insurance solutions.
                            </p>
                            <p> 
                                At Sentry—because we are a mutual insurance company—our business is built on a foundation of service to our policyholders. Although we’ve grown and changed a lot over the past 110 years, one thing hasn’t changed. We still work to serve our policyholders, and to help their businesses thrive.
                            </p>
                            <p> 
                                Our products and services are made possible by a dedicated team of associates, many of whom have been with us for decades. Over 2,000 associates in Stevens Point, Wisconsin, and another 2,000 around the country, work each day to help our policyholders prepare for the unexpected and plan for the future.
                            </p>
                            <p> 
                                CEO Pete McPartland joined us in 2010 and since then has been providing forward-thinking leadership. Under his direction, we’ve refocused our efforts on positioning ourselves as a leader in the business insurance world. We’ve also expanded our distribution network by growing with select independent agents.
                            </p>
                            <p>
                                We pride ourselves on providing individualized service to our customers. And one of the ways we do this is by using dedicated teams to serve specific industries. Over the years, departments have grown organically around our products and services.
                            </p>
                            <p> 
                                Today, our focus lies in the following markets:
                            </p> 
                            <h2>Commercial insurance</h2>
                            <p>National Accounts (NA): Think large businesses, with thousands of employees and numerous locations. We take a team approach to serving our NA customers, which means each customer is served by a dedicated team of associates. Led by Scott Miller, our NA team partners with brokers to build relationships with large businesses in diverse industries, from health services to retail.</p>
                            <p>Direct Writer: Led by John Hyland, this business unit provides insurance solutions to businesses across a range of industries. It’s made up of direct writers who work with customers in defined territories.</p>
                            <p>We specialize in business insurance for the metalworking, manufacturing, plastics, printing, distribution, and food processing industries. You can trace this avenue of the business unit back to the founding of Hardware Dealers Mutual Fire Insurance Company of Wisconsin, our original name, in the early 20th century.</p>
                            <p>We also provide property and casualty insurance and safety services to dealerships—including auto, motorcycle, agricultural equipment, and industrial equipment. We’re honored to be endorsed by John Deere® and Harley-Davidson® for our business insurance products.</p> 
                            <p>Transportation: Distributes long-haul truck insurance through a select group of industry-specialized independent agents. Led by Scott Miller, our transportation team protects companies of all sizes, from owner-operators to large fleet operations. In addition to insurance solutions, we offer our trucking customers unique safety programs driven by our certified directors of safety who, on average, bring 20+ years of industry experience to the table.</p>

                            <p>Regional: Sold solely through appointed independent agents, this part of our business provides insurance to a variety of industries including commercial construction, commercial real estate, distribution, retail, manufacturing, and service. Our growing regional business helps support these independent agents by providing local support, underwriting services, safety services, and claims services from seven offices: Montgomery, AL; Charlotte, NC; Madison, WI; Milwaukee, WI; Windsor, CT; Irvine, CA; and Portland, OR.</p>

                            <p>Hortica®: Since 2015 we’ve been affiliated with Florists’ Mutual Insurance Company. Led by Tim Noble, the Hortica brand provides insurance solutions to horticultural businesses, from nurseries to florists.</p>

                            <p>Life & Annuities: This is the department that manages our 401(k) plans and business life insurance policies. Led by Todd Schroeder, we serve our 401(k) and life insurance customers with sound investing and exceptional customer service.</p>
                            <h2>Personal insurance</h2>
                            <p>In addition to our commercial insurance products, we offer personal insurance to select consumer markets.</p>

                            <p>Dairyland®: Our personal lines brand providing motorcycle insurance and nonstandard auto insurance. Led by Pete Anhalt, our Dairyland brand specializes in serving these niche markets.</p>
                            <h2>Hospitality business</h2>
                            <p>Beyond insurance, since 1982 we’ve operated a destination golf course, event facility, and restaurant.</p>
                            <p>SentryWorld®: Our world-class 18-hole golf course, banquet facility, restaurant, and sports complex on Sentry’s home office campus. SentryWorld was ranked number 78 by Golf magazine in its Top 100 Courses You Can Play 2016/2017 category. Led by General Manager Mike James, SentryWorld hosts golfers, weddings, and events. In addition, SentryWorld boasts two beautiful restaurants: PJ’s – SentryWorld and Muse at Sentry.</p>
                            <p>We hope this snap shot of who we are helps you understand what moves our business. If there’s one thing we’ve learned in 110+ years, it’s that the marketplace changes and successful companies change with it. We’ve grown a lot since 1904, and we can help you grow too. Contact us or Find an agent to get the conversation started.</p>
                        </div>
                        <div className="work_here">
                            <h2>I wanna work here</h2>
                            <form action="">
                                {/*-------| I think this better be a <select> but not sure which options should it has |------*/}
                                <input type="text"  name='position' placeholder='Position'/>
                                <input type="email" name="email" placeholder='Email' />
                                <button type="submit">Send Request</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ready_to_work">
                <h2>Ready to work with Metamask?</h2>
                <a href="#" className="sign_up_btn">Sign Up</a>
                <p>Already have an account? <a href="#">Log in</a></p>
            </div>
        </div>
    )
}