import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import waveyLinesImage from '../../assets/images/wavy_lines.svg';
import useFetch from '../../hooks/useFetch';

import { ReactComponent as SearchIcon } from '../../assets/images/search.svg';
import Logo_1 from '../../assets/images/logos/logo_1.svg';
import Logo_2 from '../../assets/images/logos/logo_2.svg';
import Logo_3 from '../../assets/images/logos/logo_3.svg';
import Logo_4 from '../../assets/images/logos/logo_4.svg';
import PartsImage from '../../assets/images/logos/parts.svg';
import { ReactComponent as UsersIcon } from '../../assets/images/icons/people.svg';
import { ReactComponent as GalleryIcon } from '../../assets/images/icons/gallery.svg';
import { ReactComponent as HumanResources } from '../../assets/images/icons/human_resources.svg';
import { ReactComponent as PartnershipIcon } from '../../assets/images/icons/portfolio_bag_time.svg';
import { ReactComponent as FinancialIcons } from '../../assets/images/icons/user.svg';
import TeamUpLogos_1 from '../../assets/images/logos_teamup/shopify.svg';
import TeamUpLogos_2 from '../../assets/images/logos_teamup/opentable.svg';
import TeamUpLogos_3 from '../../assets/images/logos_teamup/amazon.svg';
import TeamUpLogos_4 from '../../assets/images/logos_teamup/slack.svg';
import Marketplace from '../../assets/images/logos_teamup/marketplace.svg';
import ClientsCircles from '../../assets/images/logos_teamup/clients_circles.svg';
import ImageThumbnail_1 from '../../assets/images/posts_thumbnails/thumbnail_1.jpeg';
import ImageThumbnail_2 from '../../assets/images/posts_thumbnails/thumbnail_2.jpeg';
import ImageThumbnail_3 from '../../assets/images/posts_thumbnails/thumbnail_3.jpeg';
import ImageThumbnail_4 from '../../assets/images/posts_thumbnails/thumbnail_4.jpeg';
import ImageThumbnail_5 from '../../assets/images/posts_thumbnails/thumbnail_5.jpeg';
import ImageThumbnail_6 from '../../assets/images/posts_thumbnails/thumbnail_6.jpeg';
import ImageThumbnail_7 from '../../assets/images/posts_thumbnails/thumbnail_7.jpeg';
import ImageThumbnail_8 from '../../assets/images/posts_thumbnails/thumbnail_8.jpeg';

export default function Home() {
    const [activeTab, setActiveTab] = useState('recent'); 
    const [filterQuery, setFilterQuery] = useState('https://main.jobs3.io/wp-json/wp/v2/jobs');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    useEffect(() => {
        console.log(activeTab)
        // Construct query using filter parameters
        const baseQuery = 'https://main.jobs3.io/wp-json/wp/v2/jobs';
        
        let queryParams = '';
        
        if (activeTab != 'recent')
            queryParams = `?search=${activeTab}`;
    
        setFilterQuery(baseQuery + queryParams);
    }, [activeTab]);
    console.log("filter: ", filterQuery)
    const { loading: loadingJobs, error: errorJobs, data: jobData, count: jobsNumber } = useFetch(
        `${filterQuery}`
    );

    // const jobData = [
    //     {
    //         tags: ['fulltime', 'onsite', '$300K'],
    //         title: 'UX designer',
    //         description: 'advoit digital agency',
    //         applied: 24,
    //     },
    //     {
    //         tags: ['fulltime', 'onsite', '$300K'],
    //         title: 'UX designer',
    //         description: 'advoit digital agency',
    //         applied: 24,
    //     },
    //     {
    //         tags: ['fulltime', 'onsite', '$300K'],
    //         title: 'UX designer',
    //         description: 'advoit digital agency',
    //         applied: 24,
    //     },
    //     {
    //         tags: ['fulltime', 'onsite', '$300K'],
    //         title: 'UX designer',
    //         description: 'advoit digital agency',
    //         applied: 24,
    //     },
    //     {
    //         tags: ['fulltime', 'onsite', '$300K'],
    //         title: 'UX designer',
    //         description: 'advoit digital agency',
    //         applied: 24,
    //     }, 
    //     {
    //         tags: ['fulltime', 'onsite', '$300K'],
    //         title: 'UX designer',
    //         description: 'advoit digital agency',
    //         applied: 24,
    //     },
    // ];
    const postsData = [
        {
            id: 0,
            postImage: ImageThumbnail_1,
            postTitle: 'The Essential Guide to DeFi Taxes',
            postReadMinutes: 10,
            postDate: 'Nov 23, 2022' 
        },
        {
            id: 1,
            postImage: ImageThumbnail_2,
            postTitle: 'How I became a Solidity Developer in 12 Months?',
            postReadMinutes: 10,
            postDate: 'Nov 22, 2022' 
        },
        {
            id: 2,
            postImage: ImageThumbnail_3,
            postTitle: 'Web3 Developer Salary in 2022',
            postReadMinutes: 10,
            postDate: 'May 9, 2022' 
        },
        {
            id: 3,
            postImage: ImageThumbnail_4,
            postTitle: 'How to invest in web3?',
            postReadMinutes: 7,
            postDate: 'Apr 14, 2022' 
        },
        {
            id: 4,
            postImage: ImageThumbnail_5,
            postTitle: 'Top 10 Programming Languages to Write Smart Contract',
            postReadMinutes: 15,
            postDate: 'Mar 29, 2022' 
        },
        {
            id: 5,
            postImage: ImageThumbnail_6,
            postTitle: 'How to Find and Join a DAO?',
            postReadMinutes: 5,
            postDate: 'Mar 7, 2022' 
        },
        {
            id: 6,
            postImage: ImageThumbnail_7,
            postTitle: 'Complete Web3 Developer Roadmap - 2022',
            postReadMinutes: 10,
            postDate: 'Feb 14, 2022' 
        },
        {
            id: 7,
            postImage: ImageThumbnail_8,
            postTitle: 'How to Find a Entry-Level Cryptocurrency Job?',
            postReadMinutes: 6,
            postDate: 'Jan 23, 2022' 
        }, 
    ]
    return (
        <div className='home_page'>
            <div className="hero_section">
                <div className="section_content">
                    <h1>YOUR WEB3 CAREER STARTS HERE</h1>
                    <p>Decentralising and globalising the employment landscape</p>
                    <form>
                        <input type="text" placeholder='Search: Frontend developer, Marketing, Binance, etc.' />
                        <SearchIcon />
                    </form>
                    <div className="sl_counters">
                        <div className="single_counter">
                            <strong>20M+</strong>
                            <span>users</span>
                        </div>
                        <div className="single_counter">
                            <strong>500K+</strong>
                            <span>jobs</span>
                        </div>
                        <div className="single_counter">
                            <strong>100+</strong>
                            <span>partners</span>
                        </div>
                    </div>
                    <div className="sl_logos">
                        <img src={Logo_1} />
                        <img src={Logo_2} />
                        <img src={Logo_3} />
                        <img src={Logo_4} />
                    </div>
                </div>
                <img src={waveyLinesImage} id='wavy_lines' />
            </div>
            <div className="rich_text">
                <img src={PartsImage} />
                <div className="text_side">
                    <h2>Get Paid In <span>Crypto</span></h2>
                    <p>Et in sapien enim congue interdum pulvinar non sed. Ac augue netus tellus semper.</p>
                    <a href="#" className="cta_button">get started</a>
                </div>
            </div>
            <div className="jobs_section">
                <h2>Featured <span>Opportunities</span></h2>
                <p>Get the fastest application so that your name is above other application</p>
                <div className="jobs_navigation">
                    <span className={`job_nav_item ${activeTab === 'recent' ? 'active' : ''}`}
                        onClick={() => handleTabClick('recent')} > All Recent </span>
                    <span className={`job_nav_item ${activeTab === 'finance' ? 'active' : ''}`}
                        onClick={() => handleTabClick('finance')}> Finance</span>
                    <span className={`job_nav_item ${activeTab === 'dev' ? 'active' : ''}`}
                        onClick={() => handleTabClick('dev')}> Development</span>
                    <span className={`job_nav_item ${activeTab === 'marketing' ? 'active' : ''}`}
                        onClick={() => handleTabClick('marketing')}>Marketing</span>
                    <span className={`job_nav_item ${activeTab === 'specialist' ? 'active' : ''}`}
                        onClick={() => handleTabClick('specialist')}>Specialist</span>
                </div>
                {!loadingJobs && !errorJobs && jobData && Array.isArray(jobData) && <div className="job_content">  
                    {jobData.slice(0, 6).map((job, index) => (
                        <div key={index} className="job_card">
                            {job.tags = ['fulltime', 'onsite', '$300K']}
                            <div className="job_tags">
                                {job.tags.map((tag, tagIndex) => (
                                    <div key={tagIndex} className='sl_job_tag'>{tag}</div>
                                ))}
                            </div>
                            <h3 className="job_title">{job.title.rendered}</h3>
                            {/* <p className="job_description">{job.description}</p> */}
                            <div className="card_footer">
                                {job && job.jobsearch_field_job_apply_url && <a target='_blank' href={job.jobsearch_field_job_apply_url}>Apply</a>}
                                <div className="people_applied">
                                    <UsersIcon />
                                    <span>{job.jobsearch_job_applicants_list.split(',').length} applied</span>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/*----! For Backend Developer !----*/}
                    {/*----! Use the code below to wrap the jobData dynamically with each of the job nav !----*/}

                    {/* {activeTab === 'recent' &&
                        jobData.map((job, index) => ( 
                            Rest of code here
                    } */}
                </div>}
            </div>
            <div className="categories_explore">
                <h2>explore by <span>categories</span></h2>
                <p>Nulla eget aliquet tincidunt ut turpis varius.</p>
                <div className="categories_container">
                    <div className="popular_categories">
                        <h3>Popular Categories</h3>
                        <div className="category_row">
                            <span>System analyst</span> <small>6</small>
                        </div>
                        <div className="category_row">
                            <span>Frontend developer</span> <small>12</small>
                        </div>
                        <div className="category_row">
                            <span>Backend developer</span> <small>14</small>
                        </div>
                        <div className="category_row">
                            <span>Full Stack developer</span> <small>8</small>
                        </div>
                    </div>
                    <div className="category_panels">
                        <div className="ct_panels_container">
                            <div className="sl_category_panel">
                                <GalleryIcon /> <span>Social Media & Content </span>
                            </div>
                            <div className="sl_category_panel">
                                <HumanResources /> <span>Human Resources</span>
                            </div>
                            <div className="sl_category_panel">
                                <PartnershipIcon /> <span>Partnerships & Sales </span>
                            </div>
                            <div className="sl_category_panel">
                                <FinancialIcons /> <span>Financial Accounting</span>
                            </div>
                        </div>
                        <Link to="#" className="cta_button">get started</Link>
                    </div>
                </div>
            </div>
            <div className="rich_text_3">
                <img src={ClientsCircles} />
                <div className="text_side">
                    <h2><span>More and more</span> people are being paid in crypto</h2>
                    <p>Et in sapien enim congue interdum pulvinar non sed. Ac augue netus tellus semper.</p>
                    <a href="#" className="cta_button">post a job</a>
                </div>
            </div>
            <div className="rich_text_2">
                <div className="text_side">
                    <h2>Participate in our <span>MarketPlace</span></h2>
                    <p>The hiring solution made to grow your business</p>
                </div>
                <img src={Marketplace} />
            </div>
            <div className="sl_companies">
                <h2>Team up with the most forward <span>thinking companies</span></h2>
                <div className="companies_logos">
                    <img src={TeamUpLogos_1} />
                    <img src={TeamUpLogos_2} />
                    <img src={TeamUpLogos_3} />
                    <img src={TeamUpLogos_4} />
                </div>
            </div>
            <div className="blog_posts_section">
                <h2>See Our <span>Blog</span></h2>
                <p className='section_description'>Articles on web3, remote work, guides, tutorials etc.</p>
                <div className="posts_container">
                    {postsData.map((post, index) => (
                        <div className="single_post" key={index}>
                            <img src={post.postImage} />
                            <h3>{post.postTitle}</h3>
                            <span>{post.postReadMinutes} min read • {post.postDate}</span>
                        </div>                        
                    ))}
                </div>
            </div>
        </div>
    )
}