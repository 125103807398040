import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'

/*----------- MEDIA -----------*/
import WebsiteLogo from '../assets/images/logo.svg';
import { ReactComponent as LogoutIcon } from '../dashboard-media/svgs/right_arrow.svg';
import { ReactComponent as LinkedIn } from '../dashboard-media/svgs/social/linkedin.svg';
import { ReactComponent as Twitter } from '../dashboard-media/svgs/social/x_twitter.svg';
import { ReactComponent as Facebook } from '../dashboard-media/svgs/social/facebook.svg';
import { ReactComponent as Message } from '../dashboard-media/svgs/social/message_icon.svg'; 

import { ReactComponent as ProfileIcon    } from '../dashboard-media/icons/profile.svg'; 
import { ReactComponent as ProjectsIcon   } from '../dashboard-media/icons/project.svg'; 
import { ReactComponent as ScheduleIcon   } from '../dashboard-media/icons/schedule.svg'; 
import { ReactComponent as JobListingIcon } from '../dashboard-media/icons/job_listing.svg'; 
import { ReactComponent as ContractsIcon  } from '../dashboard-media/icons/contracts.svg'; 
import { ReactComponent as StatisticsIcon } from '../dashboard-media/icons/statistics.svg'; 
import { ReactComponent as BalanceIcon    } from '../dashboard-media/icons/balance.svg'; 
import { ReactComponent as HelpIcon       } from '../dashboard-media/icons/help.svg'; 
import { ReactComponent as SettingsIcon   } from '../dashboard-media/icons/settings.svg'; 


export default function SideBar() {
    useEffect(() => {
        const menu_links = document.querySelectorAll('.main_sidebar a');
        menu_links.forEach(single_link => {
            single_link.addEventListener('click', () => {
                const menu_controls = document.querySelectorAll('.main_sidebar .menu_control')
                menu_links.forEach(will_remove_current => { will_remove_current.classList.remove('current'); });
                menu_controls.forEach(will_remove_current => { will_remove_current.classList.remove('current'); });

                single_link.classList.add('current')

                const from_dropdown = single_link.closest('.dropdown_container');
                if (from_dropdown) {
                    from_dropdown.querySelector('.menu_control').classList.add('current')
                }
            })
        });
    }, []);
    function OpenSideBar() {
        document.querySelector('.main_sidebar').classList.toggle('open')
        document.querySelector('#root').classList.toggle('no_scroll')
    } 
    return (
        <div className='main_sidebar'>
            <div className="logo_container" onClick={() => { OpenSideBar() }}>
                <img src={WebsiteLogo} alt="Logo" />
                <span>Dashboard</span>
            </div> 
            <div className="menu_content" onClick={() => { OpenSideBar() }}>
                <div className="links_container" > 
                    <div className="menu_item">
                        <Link to="/profile" className="menu_control">
                            <ProfileIcon /> <span>PROFILE</span>
                        </Link>
                    </div>
                    <div className="menu_item">
                        <Link to="/dashboard/proposals" className="menu_control">
                            <ProjectsIcon /> <span>MY PROJECTS</span>
                        </Link>
                    </div>
                    <div className="menu_item">
                        <Link to="/dashboard/proposals" className="menu_control">
                            <ScheduleIcon /> <span>MY SCHEDULE</span>
                        </Link>
                    </div>
                    <div className="menu_item">
                        <Link to="/search" className="menu_control">
                            <JobListingIcon /> <span>JOB LISTING</span>
                        </Link>
                    </div>
                    <div className="menu_item">
                        <Link to="/dashboard/proposals" className="menu_control">
                            <ContractsIcon /> <span>ALL CONTRACTS</span>
                        </Link>
                    </div>
                    <div className="menu_item">
                        <Link to="/dashboard" className="menu_control">
                            <StatisticsIcon /> <span>Statistics</span>
                        </Link>
                    </div>
                    <div className="menu_item">
                        <Link to="/dashboard" className="menu_control">
                            <BalanceIcon /> <span>BALANCE</span>
                        </Link>
                    </div>
                    <div className="menu_item">
                        <Link to="/dashboard" className="menu_control">
                            <HelpIcon /> <span>HELP</span>
                        </Link>
                    </div>
                </div> 
                <div className="links_container" >
                    <div className="menu_heading">Support</div>
                    <div className="menu_item">
                        <Link to="/dashboard/settings" className="menu_control">
                            <SettingsIcon /> <span>Settings</span>
                        </Link>
                    </div>
                </div>
                <Link to="/mydemed/logout" className='logout'>
                    <strong>Logout</strong> <LogoutIcon />
                </Link>
                <div className="menu_bottom">
                    <div className="social_icons">
                        <Link> <LinkedIn /> </Link>
                        <Link> <Twitter /> </Link>
                        <Link> <Facebook /> </Link>
                        <Link> <Message /> </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}