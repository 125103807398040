import React from 'react';

import { ReactComponent as SearchIcon } from '../../assets/images/search.svg';
import sortIcon from '../../assets/images/icons/down_chevron.svg';
import { Link } from 'react-router-dom'; 
import ImageThumbnail_1 from '../../assets/images/posts_thumbnails/thumbnail_1.jpeg';
import ImageThumbnail_2 from '../../assets/images/posts_thumbnails/thumbnail_2.jpeg';
import ImageThumbnail_3 from '../../assets/images/posts_thumbnails/thumbnail_3.jpeg';
import ImageThumbnail_4 from '../../assets/images/posts_thumbnails/thumbnail_4.jpeg';
import ImageThumbnail_5 from '../../assets/images/posts_thumbnails/thumbnail_5.jpeg';
import ImageThumbnail_6 from '../../assets/images/posts_thumbnails/thumbnail_6.jpeg';
import ImageThumbnail_7 from '../../assets/images/posts_thumbnails/thumbnail_7.jpeg';
import ImageThumbnail_8 from '../../assets/images/posts_thumbnails/thumbnail_8.jpeg';
import wavy_lines_2 from '../../assets/images/wavy_lines_2.svg';

export default function Blog() { 
    const postsData = [
        {
            id: 0,
            postImage: ImageThumbnail_1,
            postTitle: 'The Essential Guide to DeFi Taxes',
            postReadMinutes: 10,
            postDate: 'Nov 23, 2022' 
        },
        {
            id: 1,
            postImage: ImageThumbnail_2,
            postTitle: 'How I became a Solidity Developer in 12 Months?',
            postReadMinutes: 10,
            postDate: 'Nov 22, 2022' 
        },
        {
            id: 2,
            postImage: ImageThumbnail_3,
            postTitle: 'Web3 Developer Salary in 2022',
            postReadMinutes: 10,
            postDate: 'May 9, 2022' 
        },
        {
            id: 3,
            postImage: ImageThumbnail_4,
            postTitle: 'How to invest in web3?',
            postReadMinutes: 7,
            postDate: 'Apr 14, 2022' 
        },
        {
            id: 4,
            postImage: ImageThumbnail_5,
            postTitle: 'Top 10 Programming Languages to Write Smart Contract',
            postReadMinutes: 15,
            postDate: 'Mar 29, 2022' 
        },
        {
            id: 5,
            postImage: ImageThumbnail_6,
            postTitle: 'How to Find and Join a DAO?',
            postReadMinutes: 5,
            postDate: 'Mar 7, 2022' 
        },
        {
            id: 6,
            postImage: ImageThumbnail_7,
            postTitle: 'Complete Web3 Developer Roadmap - 2022',
            postReadMinutes: 10,
            postDate: 'Feb 14, 2022' 
        },
        {
            id: 7,
            postImage: ImageThumbnail_8,
            postTitle: 'How to Find a Entry-Level Cryptocurrency Job?',
            postReadMinutes: 6,
            postDate: 'Jan 23, 2022' 
        }, 
    ]
    return (
        <div className="blog_page">
            <h2>Jobs3 <span>Blog</span></h2>
            <p>Stay up-to-date with our newsletter and what we are up to!</p>
            <form>
                <input type="text" placeholder='Search: Frontend developer, Marketing, Binance, etc.' />
                <SearchIcon />
            </form>
            <div className="blog_container">
                <div className="blog_nav">
                    <div className="left_side">
                        <Link to="#">Admin & Customer Support</Link>
                        <Link to="#">Sales & Marketing</Link>
                        <Link to="#">Design & Creative</Link>
                        <Link to="#">AI Services</Link>
                        <Link to="#">Development & IT</Link>
                    </div>
                    <div className="right_side">
                        <div className="sort_btn">
                            <img src={sortIcon} /> <span>Sort by date</span> 
                        </div>
                    </div>
                </div>
                <div className="posts_container">
                    {postsData.map(post => (
                        <div className="single_post">
                            <img src={post.postImage} />
                            <h3>{post.postTitle}</h3>
                            <span>{post.postReadMinutes} min read • {post.postDate}</span>
                        </div>                        
                    ))}
                </div> 
                <div className="pagination">
                    <div className="pagination_number current_page">1</div>
                    <div className="pagination_number">2</div>
                    <div className="pagination_number">3</div>
                </div>
            </div>
            <img src={wavy_lines_2} className='wavy_lines_2' />
        </div>
    )
}