import React from 'react';
import { Route, Routes } from "react-router-dom";
import useDropdown from '../hooks/useDropdown';

import SideBar from '../components/sidebar';
import DashboardHeader from '../components/dashboard_header';

// Pages
import JobApply from '../pages/dashboard/job_apply';
import ClientDashboard from '../pages/dashboard/dashboard';
import Proposals from '../pages/dashboard/proposals'
import PreviewPopups from '../pages/dashboard/popups_for_dev'


export default function DashboardApp() {
  useDropdown();
  return (
    <div className="quanmed_dashboard_app"> 
      <SideBar />
      <div className="routes_container">
        <DashboardHeader />
        <Routes>
          <Route path="/" element={<ClientDashboard />} />
          <Route path="/job-apply" element={<JobApply />} />
          <Route path="/proposals" element={<Proposals />} />
          <Route path="/preview-popups" element={<PreviewPopups />} />
        </Routes>
      </div>
    </div>
  );
}