import React from 'react';
import { Link } from 'react-router-dom';

/*------------------| Images |------------------*/ 
import VerifiedIcon   from '../../assets/images/icons/verfified_orange.svg'; 
import Company_badge_orange from '../../assets/images/icons/orange_badge.svg';
import client_image from '../../assets/images/logos/employers/metamask.png';
import { ReactComponent as FacebookIcon } from '../../assets/images/social_media/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../assets/images/social_media/instagram.svg';
import { ReactComponent as TwitterIcon } from '../../assets/images/social_media/twitter.svg';


export default function MetaMask() {  
    return (
        <div className="employer_page">
            <div className="employer_container">
                <div className="employer_top">
                    <div className="employer_img"> 
                        <img src={client_image} />
                    </div>
                    <div className="employer_details">
                        <div className="employer_name">
                            <img src={Company_badge_orange} /> <h2>Metamask</h2> <img src={VerifiedIcon} />
                        </div>
                        <div className="employer_links">
                            <a href="https://metamask.io/">https://metamask.io</a>
                            <div className="social_links">
                                <a href="#"><FacebookIcon /></a>
                                <a href="#"><InstagramIcon /></a>
                                <a href="#"><TwitterIcon /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="employer_bottom">
                    <div className="employer_left"> 
                        <div className="employer_summary_stats">
                            <div className="single_summary">
                                <strong>01/01/1900</strong> <span>Founded Date</span>
                            </div>
                            <div className="single_summary">
                                <strong>0</strong> <span>Posted Jobs</span>
                            </div>
                        </div> 
                        <div className="title_with_links">
                            <h3>Company Sectors</h3>
                            <div className="company_links_container">
                                <a href="#">Asset Management</a>
                                <a href="#">Blockchain</a>
                                <a href="#">Consumer App</a>
                                <a href="#">Cryptocurrency</a>
                                <a href="#">dApps</a>
                                <a href="#">Defi Protocol</a>
                                <a href="#">Infrastructure</a>
                                <a href="#">NFT</a>
                                <a href="#">Wallet</a> 
                            </div>
                        </div>
                        <div className="title_with_links">
                            <h3>HQ Location</h3>
                            <div className="company_links_container">
                                <a href="#">United Kingdom</a>
                            </div>
                        </div>
                    </div>
                    <div className="employer_right">
                        <div className="company_description">
                            <h3>Company Description</h3>
                            <h2>A crypto wallet & gateway to blockchain apps</h2>
                            <p>Start exploring blockchain applications in seconds. Trusted by over 30 million users worldwide.</p>

                            <h2>Buy, store, send and swap tokens</h2>
                            <p>Available as a browser extension and as a mobile app, MetaMask equips you with a key vault, secure login, token wallet, and token exchange —everything you need to manage your digital assets.</p>

                            <h2>Explore blockchain apps</h2>
                            <p>MetaMask provides the simplest yet most secure way to connect to blockchain-based applications. You are always in control when interacting on the new decentralized web.</p>

                            <h2>Own your data</h2>
                            <p>MetaMask generates passwords and keys on your device, so only you have access to your accounts and data. You always choose what to share and what to keep private.</p>
                        </div>
                        <div className="work_here">
                            <h2>I wanna work here</h2>
                            <form action="">
                                {/*-------| I think this better be a <select> but not sure which options should it have |------*/}
                                <input type="text"  name='position' placeholder='Position'/>
                                <input type="email" name="email" placeholder='Email' />
                                <button type="submit">Send Request</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ready_to_work">
                <h2>Ready to work with Metamask?</h2>
                <a href="#" className="sign_up_btn">Sign Up</a>
                <p>Already have an account? <a href="#">Log in</a></p>
            </div>
        </div>
    )
}