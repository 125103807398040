import React from 'react';
import { Link } from 'react-router-dom';

import TwitterIcon from '../assets/images/social_media/twitter.svg';
import FacebookIcon from '../assets/images/social_media/facebook.svg';
import InstagramIcon from '../assets/images/social_media/instagram.svg';
import Logo from '../assets/images/logo.svg';
import wave_lines from '../assets/images/wavy-lines-footer.svg'

export default function Footer() {
    return (
        <footer>
            <img src={wave_lines} className='wave_lines_footer'/>
            <div className="email_subscribe">
                <p>Always get the <span>latest</span> information</p>
                <form>
                    <input type="text" placeholder='Email.' />
                    <button>subscribe</button> 
                </form>
            </div>
            <div className="footer_bottom"> 
                <img src={Logo} className='footer_logo' />
                <p className='footer_description'>Find Us On Social Media:</p>
                <div className="social_icons">
                    <Link to="#" className='icon_circle'><img src={FacebookIcon} /></Link>
                    <Link to="#" className='icon_circle'><img src={TwitterIcon} /></Link>
                    <Link to="#" className='icon_circle'><img src={InstagramIcon} /></Link>
                </div>
                <hr />
                <div className="footer_links">
                    <div className="footer_links_column">
                        <Link to="/">
                            <img src={Logo} />
                        </Link>
                    </div>
                    <div className="footer_links_column">
                        <h3>Roles</h3> 
                        <Link to="/customer-support">Customer Support</Link>
                        <Link to="/design">Design</Link>
                        <Link to="/engineering">Engineering</Link>
                        <Link to="/entry-level">Entry-Level</Link>
                        <Link to="/finance">Finance</Link>
                        <Link to="/internships">Internships</Link>
                        <Link to="/marketing">Marketing</Link>
                        <Link to="/non-tech">Non-Tech</Link>
                        <Link to="/operations">Operations</Link>
                        <Link to="/product">Product</Link>
                        <Link to="/sales">Sales</Link>
                        <Link to="/other">Other</Link>
                    </div>
                    <div className="footer_links_column">
                        <h3>Collections</h3> 
                        <Link to="/blockchain-jobs">Blockchain Jobs</Link>
                        <Link to="/bitcoin-jobs">Bitcoin Jobs</Link>
                        <Link to="/cosmos-jobs">Cosmos Jobs</Link>
                        <Link to="/ethereum-jobs">Ethereum Jobs</Link>
                        <Link to="/solana-jobs">Solana Jobs</Link>
                        <Link to="/dao-jobs">DAO Jobs</Link>
                        <Link to="/defi-jobs">DeFi Jobs</Link>
                        <Link to="/layer-2-jobs">Layer 2 Jobs</Link>
                        <Link to="/nft-jobs">NFT Jobs</Link>
                        <Link to="/remote-jobs">Remote Jobs</Link>
                        <Link to="/pays-in-crypto">Pays in Crypto</Link>
                        <Link to="/streams-in-crypto">Streams in Crypto</Link>
                    </div>
                    <div className="footer_links_column"> 
                        <h3>Resources</h3>
                        <Link to="/blog">Blog</Link>
                        <Link to="/post-a-job">Hiring? Post a Job</Link>
                        <Link to="/job-alerts">Job Alerts</Link>
                        <Link to="/job-collections">Job Collections</Link>
                        <Link to="/newsletter">Newsletter</Link>
                        <Link to="/salaries">Salaries</Link>
                        <Link to="/startup-directory">Startup Directory</Link>
                        <Link to="/talent-collective">Talent Collective</Link>
                    </div>
                    <div className="footer_links_column"> 
                        <h3>About</h3>
                        <Link to="/about-us">About Us</Link>
                        <Link to="/advertise">Advertise</Link>
                        <Link to="/partners">Partners</Link>
                        <Link to="/press">Press</Link>
                        <Link to="/pricing">Pricing</Link>
                        <Link to="/testimonials">Testimonials</Link>
                        <Link to="/twitter">Twitter</Link>
                        <Link to="/contact-us">Contact Us</Link>
                    </div>
                </div>
                <p>Copyright © 2024</p>
            </div>
        </footer>
    )
}