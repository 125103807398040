import React, { useState } from "react";
import { Link } from "react-router-dom";

/*--------- Hooks ---------*/
import { usePopupFunctions } from "../hooks/popups";

/*---------------| images |---------------*/
import { ReactComponent as LogoSVG } from "../assets/images/logo.svg";
import { ReactComponent as BarsSVG } from "../assets/images/menu_icon.svg";

export default function Header() {
	const { openPopup, renderPopup } = usePopupFunctions();
	const [menuOpen, setMenuOpen] = useState(false);

	const handleMenuClick = () => {
		setMenuOpen(!menuOpen); // Toggle the menu state
	};

	return (
		<header className="main_header">
			{renderPopup()}
			<LogoSVG className="main_logo" />
			<BarsSVG className="menu_bars" onClick={handleMenuClick} />
			<nav className={menuOpen ? "open_menu" : ""}>
				<Link to="/">HOME</Link>
				<Link to="/jobs">JOBS</Link>
				{/* <Link to="/search">Talents</Link>
                <Link to="/pricing">Pricing</Link>
                <Link to="/blog">More</Link> */}
				<div className="dropdown-link-mobile">
					<Link to="#">$THREE</Link>
					<ul>
						<li>
							<Link
								to="https://three-3.gitbook.io/litepaper"
								target="_blank"
							>
								LitePaper
							</Link>
						</li>
						<li>
							<Link to="#">Tokenomics</Link>
						</li>
					</ul>
				</div>
			</nav>
			<div className={`right_side ${menuOpen ? "open_menu" : ""}`}>
				<div className="dropdown-link">
					<Link to="#">$THREE</Link>
					<ul>
						<li>
							<Link
								to="https://three-3.gitbook.io/litepaper"
								target="_blank"
							>
								LitePaper
							</Link>
						</li>
						<li>
							<Link to="#">Tokenomics</Link>
						</li>
					</ul>
				</div>
				<Link
					className="btn_sign_up"
					onClick={() => openPopup("SignUp")}
				>
					Sign Up
				</Link>
				<Link className="btn_classified" to="#">
					<div className="btn_classified_content">Classified</div>
				</Link>
			</div>
		</header>
	);
}
