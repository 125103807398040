import React from 'react';

import { SignUpPopup, SignInPopup } from '../../components/popups/popups_components';

const LoginPage = ()=> {
    return (
        <div className="login_page">
            <SignInPopup />
            <SignUpPopup />
        </div>
    )
}
export default LoginPage;