import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import MainApp from './layouts/main_layout';
import DashboardApp from './layouts/dashboard_layout'
import ReactGA from 'react-ga';

// Replace 'G-9LL30FF267' with your measurement ID
ReactGA.initialize('G-9LL30FF267');

function App() {
  useEffect(() => {
      ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="/*" element={<MainApp />} />
        <Route path="/dashboard/*" element={<DashboardApp />} />
      </Routes>
    </Router>
  );
}

export default App;