import React from 'react';
import { Link } from 'react-router-dom';

/*------------------| Images |------------------*/
import ProfileImage   from '../../assets/images/logos/employers/energy-core-limited.png'; 
import VerifiedIcon   from '../../assets/images/icons/verfified_orange.svg'; 
import Company_badge_orange from '../../assets/images/icons/orange_badge.svg';
import client_image from '../../assets/images/logos/employers/energy-core-limited.png'; 
import { ReactComponent as FacebookIcon } from '../../assets/images/social_media/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../assets/images/social_media/instagram.svg';
import { ReactComponent as TwitterIcon } from '../../assets/images/social_media/twitter.svg';


export default function EnergyCoreLimited() {  
    return (
        <div className="employer_page">
            <div className="employer_container">
                <div className="employer_top">
                    <div className="employer_img"> 
                        <img src={client_image} />
                    </div>
                    <div className="employer_details">
                        <div className="employer_name">
                            <img src={Company_badge_orange} /> <h2>Energi Core Limited</h2> <img src={VerifiedIcon} />
                        </div>
                        <div className="employer_links">
                            <a href="https://energi.world//">https://energi.world/</a>
                            <div className="social_links">
                                <a href="#"><FacebookIcon /></a>
                                <a href="#"><InstagramIcon /></a>
                                <a href="#"><TwitterIcon /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="employer_bottom">
                    <div className="employer_left"> 
                        <div className="employer_summary_stats">
                            <div className="single_summary">
                                <strong>01/01/2017</strong> <span>Founded Date</span>
                            </div>
                            <div className="single_summary">
                                <strong>0</strong> <span>Posted Jobs</span>
                            </div>
                        </div> 
                        <div className="title_with_links">
                            <h3>Company Sectors</h3>
                            <div className="company_links_container">
                                <a href="#">Asset Management</a>
                                <a href="#">Blockchain</a>
                                <a href="#">Cryptocurrency</a>
                                <a href="#">Defi Protocol</a>
                                <a href="#">Infrastructure</a>
                                <a href="#">Tokenization</a>
                                <a href="#">Wax</a>
                            </div>
                        </div>
                        <div className="title_with_links">
                            <h3>HQ Location</h3>
                            <div className="company_links_container">
                                <a href="#">United Kingdom</a>
                            </div>
                        </div>
                    </div>
                    <div className="employer_right">
                        <div className="company_description">
                            <h2>Company Description</h2>

                            <p>In 2017, tech-influencer and visionary, Tommy WorldPower conceived an economically-optimized cryptocurrency, combined with smart contract capabilities, layer 2 scalability, and decentralized governance, built on self-sufficient funding, with strong moral leadership.</p>

                            <p>Through Tommy's leadership and vision, he has self-funded Energi with no initial coin offering (ICO) or pre-mine.</p>

                            <p>Since then, Energi has continued to grow and expand through its unwavering commitment to benefit the Energi community.</p>

                            <p>Under Tommy's guidance, the Energi ecosystem is now composed of several features that most crypto projects don't have. The unparalleled benefits Energi has provided its community include:</p>

                            <h2>Security</h2>
                            <p>Energi is the most secure blockchain network in the industry that eliminates 95% of scams and fraud.</p>

                            <h2>Energiswap</h2>
                            <p>Energiswap is the most Secure Decentralized Exchange (DEX), featuring hundreds of trading pairs, low fees and fast transaction speed.</p>

                            <h2>Yield Farming</h2>
                            <p>Energi offers several dozen Farming Pools that offer high-yield returns. We also offer the Energi's stablecoin (USDE) to park profits, maintain liquidity, and trade-in with other coins.</p>

                            <h2>Referral Program</h2>
                            <p>Energiswap offers the only Decentralized Referral Program in DeFi, where users can receive rewards and fee discounts by inviting their friends to trade on our DEX.</p>
                        </div>
                        <div className="work_here">
                            <h2>I wanna work here</h2>
                            <form action="">
                                {/*-------| I think this better be a <select> but not sure which options should it have |------*/}
                                <input type="text"  name='position' placeholder='Position'/>
                                <input type="email" name="email" placeholder='Email' />
                                <button type="submit">Send Request</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ready_to_work">
                <h2>Ready to work with Metamask?</h2>
                <a href="#" className="sign_up_btn">Sign Up</a>
                <p>Already have an account? <a href="#">Log in</a></p>
            </div>
        </div>
    )
}