import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

/*--------------| Imports |--------------*/
import Header from '../components/header'; 
import Footer from '../components/footer';

/*--------------| Pages |--------------*/
import Home from '../pages/main-pages/home';
import Search from '../pages/main-pages/search';
import Blog from '../pages/main-pages/blog';
import About from '../pages/main-pages/about';
import Profile from '../pages/main-pages/profile';
import Pricing from '../pages/main-pages/pricing'; 
import EmployerPage from '../pages/main-pages/employer';
import LoginPage from '../pages/main-pages/login';

import BlogPost_1 from '../pages/posts/entering_blockchain';
import BlogPost_2 from '../pages/posts/is-a-web3-internship';
import BlogPost_3 from '../pages/posts/nft-artist-jobs-guide';
import BlogPost_4 from '../pages/posts/solidity-vs-rust-blockchain-developer';
import BlogPost_5 from '../pages/posts/web3-community-managers-salary';
import BlogPost_6 from '../pages/posts/web3-developer-salary-information'; 

import Sentry from '../pages/employers/sentry';
import GalaGames from '../pages/employers/gala-games';
import EnergyCoreLimited from '../pages/employers/energy-core-limited';
import RareSkills from '../pages/employers/rareskills';
import Risidio from '../pages/employers/risidio';
import CoinCallExchange from '../pages/employers/coincall-exchange';
import Machinations from '../pages/employers/machinations'; 
import MetaMask from '../pages/employers/metamask';

import SingleJob from '../pages/main-pages/single-job';


export default function MainApp() {
  const location = useLocation();
  let currentLocation = location.pathname; 
  currentLocation = currentLocation.startsWith('/') ? currentLocation.slice(1) : currentLocation; 
  if (currentLocation === '') {
    currentLocation = 'home';
  }
  
  return (
    <div className={`main_layout ${currentLocation}_template`}>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/search" element={<Search />} />
        <Route path="/jobs" element={<Search />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/about" element={<About />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/employer" element={<EmployerPage />} />
        <Route path="/login" element={<LoginPage />} />
        {/* Blog Pages */}
        <Route path="/entry-level-web3-jobs" element={<BlogPost_1 />} />
        <Route path="/is-a-web3-internship-right-for-me" element={<BlogPost_2 />} />
        <Route path="/nft-artist-jobs-guide" element={<BlogPost_3 />} />
        <Route path="/solidity-vs-rust-blockchain-developer" element={<BlogPost_4 />} />
        <Route path="/web3-community-managers-salary" element={<BlogPost_5 />} />
        <Route path="/web3-developer-salary-information" element={<BlogPost_6 />} /> 
        {/* Employer Pages */}
        <Route path="/employer/sentry" element={<Sentry />} />
        <Route path="/employer/metamask" element={<MetaMask />} />
        <Route path="/employer/coincall-exchange" element={<CoinCallExchange />} />
        <Route path="/employer/risidio" element={<Risidio />} />
        <Route path="/employer/rareskills" element={<RareSkills />} />
        <Route path="/employer/energi-core-limited" element={<EnergyCoreLimited />} />
        <Route path="/employer/gala-games" element={<GalaGames />} />
        <Route path="/employer/machinations" element={<Machinations />} /> 
        {/* Jobs Pages */}
        <Route path="/music-licensing-jobs" element={<Search search_text="Music Licensing Jobs" />} />
        <Route path="/discord-manager-jobs" element={<Search search_text="Discord Manager Jobs" />} />
        <Route path="/discord-bot-developer-jobs" element={<Search search_text="Discord Bot Developer Jobs" />} /> 
        <Route path="/discord-mod-jobs" element={<Search search_text="Discord mod jobs" />} /> 
        <Route path="/reddit-marketing-jobs" element={<Search search_text="Reddit Marketing Jobs" />} /> 
        <Route path="/security-jobs" element={<Search search_text="Security Jobs" />} /> 
        <Route path="/consulting-jobs" element={<Search search_text="Consulting Jobs" />} /> 
        {/* Single Job Page */}
        <Route path="/blockchain-engineer-lead-cto-solidity-typescript" element={<SingleJob />} />  
      </Routes>
      <Footer />
    </div>
  );
}
