import React, { useState } from 'react'; 
import { Link } from 'react-router-dom';

import waves_1 from '../../assets/images/left_down_waves_1.svg';
import TeamUpLogos_1 from '../../assets/images/logos_teamup/shopify.svg';
import TeamUpLogos_2 from '../../assets/images/logos_teamup/opentable.svg';
import TeamUpLogos_3 from '../../assets/images/logos_teamup/amazon.svg';
import TeamUpLogos_4 from '../../assets/images/logos_teamup/slack.svg';
import { ReactComponent as UsersIcon } from '../../assets/images/icons/people.svg';
import waves_about from '../../assets/images/waves_about_footer.svg';

import waves_mobile_top from '../../assets/images/waves_about_mobile_top.svg';
import waves_mobile_bottom from '../../assets/images/waves_about_mobile_bottom.svg';

export default function About() {  
    const [activeTab, setActiveTab] = useState('recent');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };
    const jobData = [
        {
            tags: ['fulltime', 'onsite', '$300K'],
            title: 'UX designer',
            description: 'advoit digital agency',
            applied: 24,
        },
        {
            tags: ['fulltime', 'onsite', '$300K'],
            title: 'UX designer',
            description: 'advoit digital agency',
            applied: 24,
        },
        {
            tags: ['fulltime', 'onsite', '$300K'],
            title: 'UX designer',
            description: 'advoit digital agency',
            applied: 24,
        },
        {
            tags: ['fulltime', 'onsite', '$300K'],
            title: 'UX designer',
            description: 'advoit digital agency',
            applied: 24,
        },
        {
            tags: ['fulltime', 'onsite', '$300K'],
            title: 'UX designer',
            description: 'advoit digital agency',
            applied: 24,
        }, 
        {
            tags: ['fulltime', 'onsite', '$300K'],
            title: 'UX designer',
            description: 'advoit digital agency',
            applied: 24,
        },
    ];
    return (
        <div className="about_page">
            <div className="heading_section">
                <h2 className="about_heading">About Us</h2> 
            </div>
            <div className="about_text_container">
                <div className="about_txt">
                    <h3>THE <span>SITUATION</span></h3>
                    <p>The Web 3.0 and blockchain global market is predicted to reach $69.04B by 2027. “Web 3 innovations will take the internet into new realms and give rise to applications not previously possible.” Avivah Litan, VP Analyst at Gartner</p>
                </div>
                <img src={waves_mobile_top} className='waves_mobile_top' />
                <div className="about_txt">
                    <h3>WHY <span>US</span></h3>
                    <p>Jobs3 has been built from the ground up by a team of creatives, developers and blockchain project leaders. We understand the needs of businesses and projects attempting to assemble high quality teams in the fast-developing Web 3.0 space, and we’ve experienced first-hand how hard it is to find good people. This is the key reason we created Jobs3. We know everything it takes to build successful blockchain brands – the big challanges and the small. This is why… As well as high-level developers and engineers, our jobs board is aimed at creatives and more niche roles within the Web 3.0 space. We accept crypto payments. We have a unique marketing and referral program. Our website allows direct feedback to our team via our custom built plug in, Feedbird.</p>
                </div>
                <img src={waves_mobile_bottom} className='waves_mobile_bottom' />
                <div className="about_txt">
                    <h3>THE <span>PROBLEM</span></h3>
                    <p>As a business, you want to prepare for the future and take advantage of the many opportunities Web 3.0 will offer. However, the terminology – blockchain, NFTs, metaverse – is confusing, and building an experienced, talented workforce in this developing space is tough.</p>
                </div>
                <img src={waves_1} className='waves_2' />
            </div>
            <div className="jobs_section">
                <h2>The <span>Team</span></h2>
                <p>Get the fastest application so that your name is above other application</p>
                <div className="jobs_navigation">
                    <span className={`job_nav_item ${activeTab === 'recent' ? 'active' : ''}`}
                        onClick={() => handleTabClick('recent')} > All Recent </span>
                    <span className={`job_nav_item ${activeTab === 'finance' ? 'active' : ''}`}
                        onClick={() => handleTabClick('finance')}> Finance</span>
                    <span className={`job_nav_item ${activeTab === 'dev' ? 'active' : ''}`}
                        onClick={() => handleTabClick('dev')}> Development</span>
                    <span className={`job_nav_item ${activeTab === 'marketing' ? 'active' : ''}`}
                        onClick={() => handleTabClick('marketing')}>Marketing</span>
                    <span className={`job_nav_item ${activeTab === 'specialist' ? 'active' : ''}`}
                        onClick={() => handleTabClick('specialist')}>Specialist</span>
                </div>
                <div className="job_content"> 
                    {jobData.map((job, index) => (
                        <div key={index} className="job_card">
                            <div className="job_tags">
                                {job.tags.map((tag, tagIndex) => (
                                    <div key={tagIndex} className='sl_job_tag'>{tag}</div>
                                ))}
                            </div>
                            <h3 className="job_title">{job.title}</h3>
                            <p className="job_description">{job.description}</p>
                            <div className="card_footer">
                                <a href="#apply">Apply</a>
                                <div className="people_applied">
                                    <UsersIcon />
                                    <span>{job.applied} applied</span>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/*----! For Backend Developer !----*/}
                    {/*----! Use the code below to wrap the jobData dynamically with each of the job nav !----*/}

                    {/* {activeTab === 'recent' &&
                        jobData.map((job, index) => ( 
                            Rest of code here
                    } */}
                </div>
            </div>
            <div className="sl_companies">
                <h2>Work with exciting 100+ <span>companies</span> in the world</h2>
                <div className="companies_logos">
                    <img src={TeamUpLogos_1} />
                    <img src={TeamUpLogos_2} />
                    <img src={TeamUpLogos_3} />
                    <img src={TeamUpLogos_4} />
                </div>
            </div>
            <div className="rich_text"> 
                <div className="text_side">
                    <h2>So Many People are <span>engaged</span> all over the world</h2>
                    <p>Et in sapien enim congue interdum pulvinar non sed. Ac augue netus tellus semper.</p>
                    <a href="#" className="cta_button">post a job</a>
                </div>
            </div>
            <img src={waves_about} className='waves_about' />
        </div>
    )
}