import React from 'react';
import { Link } from 'react-router-dom';

/*------------------| Images |------------------*/
import VerifiedIcon   from '../../assets/images/icons/verfified_orange.svg'; 
import Company_badge_orange from '../../assets/images/icons/orange_badge.svg';
import { ReactComponent as FacebookIcon } from '../../assets/images/social_media/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../assets/images/social_media/instagram.svg';
import { ReactComponent as TwitterIcon } from '../../assets/images/social_media/twitter.svg';
// This image is just a placeholder
import client_image from '../../assets/images/logos/employers/metamask.png';

export default function SingleJob() {  
    return (
        <div className="sl_job_page">
            <div className="sl_job_container">
                <div className="sl_job_top">
                    <div className="sl_job_img"> 
                        <img src={client_image} />
                    </div>
                    <div className="sl_job_details">
                        <div className="sl_job_name">
                            <img src={Company_badge_orange} /> <h2>Sr. Blockchain Engineer Lead/CTO (Solidity/Typescript)</h2>
                        </div>
                        <div className="job_details">
                            <div className="company_name">CRASTONIC Ltd</div>
                            <div className="job_count_details">
                                <span> 10 days ago </span>
                                <span> 293 applications </span>
                            </div>
                            <ul>
                                <li>Tech  </li>
                                <li>Full Time</li>
                                <li>Everywhere</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="sl_job_bottom">
                    <div className="sl_job_left"> 
                        <div className="sl_job_cmp">
                            <h3>Compensation</h3>
                            <p>$13k – $70k + Token + Equity</p>
                        </div>
                        <div className="title_with_links">
                            <h3>Skills</h3>
                            <div className="company_links_container">
                                <a href="#">Figma</a>
                                <a href="#">Graphic Design</a>
                                <a href="#">Mobile App Design</a>
                                <a href="#">Landing Page</a>
                                <a href="#">Web Design</a>
                                <a href="#">Mobile UI Design</a>
                                <a href="#">User Interface Design</a>
                                <a href="#">UX & UI</a>
                                <a href="#">Website Redesign</a>
                                <a href="#">Wireframing</a>
                                <a href="#">Webflow</a>
                                <a href="#">Design-to-Code</a>
                                <a href="#">User Experience Design</a>
                                <a href="#">UI/UX Prototyping</a>
                            </div>
                        </div>
                    </div>
                    <div className="sl_job_right">
                        <div className="company_description">
                            <h1>About the Job</h1>
                            <p>Looking for a skillful and passionate Blockchain engineer lead/CTO to make continuous innovation in web3 spaces.</p>
                            <p>Our service site: DeCir.io</p>
                            <p>
                                ◾︎ Job Description
                                -Work full-time remotely or in person
                                -Build, lead, equip & manage the entire Web3 team and propose innovative ideas from a technical point of view
                                -Responsible for the entire development architecture & security of the product
                                -Oversee the full spectrum of business ideas & market trends, and apply the latest technology to enhance the overall user experience
                                -Investigate and specify ideas for innovative blockchain applications.
                                -Design and develop a proof of concepts for blockchain applications.
                                -Design and develop blockchain-related microservices and APIs comfortably.
                                -Plan and execute performance tests using test-driven development.
                                -Collaborate with BizDev, PdM, Marketing, and teams to meet specification needs in line with business objectives.
                                -Work closely with the CEO and teams to meet specification needs in line with business objectives. ️Improve products and pursue successful customer value experiences.
                            </p>
                        </div>
                        <div className="work_here">
                            <a className="apply_btn" href='#'>Apply Now</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ready_to_work">
                <h2>Ready to work with CRASTONIC Ltd?</h2>
                <a href="#" className="sign_up_btn">Sign Up</a>
                <p>Already have an account? <a href="#">Log in</a></p>
            </div>
        </div>
    )
}