import React, { useState } from 'react';

/*------------------| Images |------------------*/
import MessagesIcon from '../../assets/images/icons/messages_icon.svg';
import ClockIcon from '../../assets/images/icons/clock.svg';
import Users_3 from '../../assets/images/icons/3_users.png';
import ChevronDown from '../../assets/images/icons/chevron-down.svg';
import PlusIcon from '../../assets/images/icons/plus_icon.svg';
import PricingWaves from '../../assets/images/pricing_waves_desktop.svg';


export default function Pricing() {
    const [openAccordion, setOpenAccordion] = useState(null);

    const handleAccordionClick = (index) => {
      setOpenAccordion(openAccordion === index ? null : index);
    };
    const accordionData = [
        {
          title: 'Auth Services',
          content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit...',
        },
        {
          title: 'Branding',
          content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit...',
        },
        {
          title: 'Wallet Services',
          content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit...',
        },
        {
          title: 'Support',
          content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit...',
        },
        {
          title: 'Quality of Life Add Ons',
          content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit...',
        },
    ];
    const faqData = [
        {
          title: 'How do I get started?',
          content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit...',
        },
        {
          title: 'How is Monthly Active Wallet (MAW) calculated?',
          content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit...',
        },
        {
          title: 'Whats Web3Auths uptime and status?',
          content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit...',
        },
        {
          title: 'What kind of support will I receive?',
          content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit...',
        },
    ];
    const faqData2 = [
        {
          title: 'How do I sign up for Growth or Scale plan?',
          content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit...',
        },
        {
          title: 'What happens if I surpass the free MAWsincluded in my subscription?',
          content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit...',
        },
        {
          title: 'Can I switch to a different plan anytime?',
          content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit...',
        },
        {
          title: 'What is the billing cycle?',
          content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit...',
        },
    ];

    const [showMoreFAQ, setShowMoreFAQ] = useState(false);
    return (
        <div className="pricing_page">
            <div className="pricing_heading">
                <h2><span>Pricing plans</span> that suit your needs</h2>
            </div>
            <div className="pricing_tables">
                <div className="pricing_table">
                    <div className="table_name">Growth</div>
                    <h3><span>$</span>69<span>/month</span></h3>
                    <p>For teams that require basic whitelabelling and custom logins</p>
                    <div className="features_list">
                        <div className="single_feature">
                            <img src={MessagesIcon} width="20" />
                            <span>
                                Free 3,000 Monthly Active Wallets (MAWs) <br />
                                +$0.03 per Additional MAW
                            </span>
                        </div>
                        <div className="single_feature">
                            <img src={MessagesIcon} width="20" />
                            <span>Customizable Social Login Providers + your Own Auth (JWTs)</span>
                        </div>
                        <div className="single_feature">
                            <img src={MessagesIcon} width="20" />
                            <span>Customizable Modal, Redirect & Login Screens</span>
                        </div>
                        <div className="single_feature">
                            <img src={MessagesIcon} width="20" />
                            <span>4 Team Seats</span>
                        </div>
                        <div className="single_feature">
                            <img src={MessagesIcon} width="20" />
                            <span>Community Support</span>
                        </div>
                    </div>
                    <div className="table_button">Contact sales</div>
                </div>
                <div className="pricing_table">
                    <div className="table_name">Enterprise</div>
                    <h3>Custom Pricing</h3>
                    <p>For teams and companies that require advanced features and dedicated support</p>
                    <div className="features_list">
                        <div className="single_feature">
                            <img src={ClockIcon} width="20" />
                            <span> Volume Based MAW Discounts </span>
                        </div>
                        <div className="single_feature">
                            <img src={ClockIcon} width="20" />
                            <span>Ready-to-launch White Label Wallet</span>
                        </div>
                        <div className="single_feature">
                            <img src={ClockIcon} width="20" />
                            <span>Access to Advanced Customised Auth (Custom Domain, SMTP) + SSO</span>
                        </div>
                        <div className="single_feature">
                            <img src={ClockIcon} width="20" />
                            <span>Advanced MFA</span>
                        </div>
                        <div className="single_feature">
                            <img src={ClockIcon} width="20" />
                            <span>MPC TSS Support for Web and Mobile</span>
                        </div>
                        <div className="single_feature">
                            <img src={ClockIcon} width="20" />
                            <span>Fully Customizable Login Experience</span>
                        </div>
                        <div className="single_feature">
                            <img src={ClockIcon} width="20" />
                            <span>Unlimited Team Seats</span>
                        </div>
                        <div className="single_feature">
                            <img src={ClockIcon} width="20" />
                            <span>Uptime SLA Commitment</span>
                        </div>
                        <div className="single_feature">
                            <img src={ClockIcon} width="20" />
                            <span>Dedicated Technical Support + Committed Response Times</span>
                        </div>
                    </div>
                    <div className="table_button">Contact sales</div>
                </div>
                <div className="pricing_table">
                    <div className="table_name">Scale <span className='table_badge'>POPULAR</span></div>
                    <h3><span>$</span>399<span>/month</span></h3>
                    <p>For teams that require higher levels of customization and support</p>
                    <div className="features_list">
                        <div className="single_feature">
                            <img src={MessagesIcon} width="20" />
                            <span>
                                Free 10,000 Monthly Active Wallets (MAWs) <br />
                                +$0.025 per Additional MAW
                            </span>
                        </div>
                        <div className="single_feature">
                            <img src={MessagesIcon} width="20" />
                            <span>Customizable Social Login Providers + your Own Auth (JWTs)</span>
                        </div>
                        <div className="single_feature">
                            <img src={MessagesIcon} width="20" />
                            <span>Customizable Modal, Redirect & Login Screens</span>
                        </div>
                        <div className="single_feature">
                            <img src={MessagesIcon} width="20" />
                            <span>8 Team Seats</span>
                        </div>
                        <div className="single_feature">
                            <img src={MessagesIcon} width="20" />
                            <span>Enhanced Community Support + Technical Advisory Call</span>
                        </div>
                    </div>
                    <div className="table_button">Contact sales</div>
                </div>
                <div className="pricing_table">
                    <span className="table_name">Base</span>
                    <h3>Free</h3>
                    <p>For individuals on personal workspaces</p>
                    <div className="features_list">
                        <div className="single_feature">
                            <img src={Users_3} width="20" />
                            <span>
                                Free 1,000 Monthly Active Wallets (MAWs) <br />
                                +$0.04 per Additional MAW
                            </span>
                        </div>
                        <div className="single_feature">
                            <img src={Users_3} width="20" />
                            <span>Access to Built-in Social Login Providers</span>
                        </div>
                        <div className="single_feature">
                            <img src={Users_3} width="20" />
                            <span>Customizable Modal</span>
                        </div>
                        <div className="single_feature">
                            <img src={Users_3} width="20" />
                            <span>1 Team Seat</span>
                        </div>
                        <div className="single_feature">
                            <img src={Users_3} width="20" />
                            <span>Community Support</span>
                        </div>
                    </div>
                    <div className="table_button">Contact sales</div>
                </div>
            </div>
            <div className="pricing_info">
                <p><strong>1 Monthly Active Wallet (MAW)</strong> = Up to 5 logins per unique wallet per month </p>
                <p>Formula: Total logins per unique wallet divided by 5 =  Total MAWs</p>
                <p>For example, under Growth Plan, $0.03 is charged per additional MAW.</p>
                <p>If a unique wallet does 17 logins, this is counted as 4 MAWs (17/5=4 ~ round up to whole number).</p>
            </div>
            <div className="packages_comparison">
                <table cellspacing="0" cellpadding="0">
                    <thead>
                        <tr>
                            <td></td>
                            <td>Base</td>
                            <td></td>
                            <td>Scale</td>
                            <td>Enterprise</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Monthly Price</td>
                            <td>Free</td>
                            <td>$69</td>
                            <td>$399</td>
                            <td>Custom pricing</td>
                        </tr>
                        <tr>
                            <td>Monthly Active Wallets (MAW*)</td>
                            <td>1,000 free</td>
                            <td>3,000 free</td>
                            <td>10,000 free</td>
                            <td>Volume Discounts</td>
                        </tr>
                        <tr>
                            <td>Fee per Additional MAW</td>
                            <td>$0.04</td>
                            <td>$0.03</td>
                            <td>$0.025</td>
                            <td>-</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="pricing_accordion">
                {accordionData.map((item, index) => (
                    <div className={`single_accordion ${openAccordion === index ? 'open' : ''}`} key={index}>
                        <div className="accordion_heading" onClick={() => handleAccordionClick(index)}>
                            <span>{item.title}</span> <img src={ChevronDown} className="chevron_down" />
                        </div>
                        <div className="accordion_content">
                            <p>{item.content}</p>
                        </div> 
                    </div>
                ))}
            </div>
            <div className="have_question">
                <h2>Have more questions?</h2>
                <p>View other FAQs on </p>
                <strong>Community Discussion</strong>
                <div className="faq_section">
                    <h3>General</h3>
                    {faqData.map((item, index) => (
                        <div className={`single_accordion ${openAccordion === index ? 'open' : ''}`} key={index}>
                            <div className="accordion_heading" onClick={() => handleAccordionClick(index)}>
                                <span>{item.title}</span> <img src={PlusIcon} className="chevron_down" />
                            </div>
                            <div className="accordion_content">
                                <p>{item.content}</p>
                            </div> 
                        </div>
                    ))}
                </div>
                <div className="faq_section">
                    <h3>BILLING</h3>
                    {faqData2.map((item, index) => (
                        <div className={`single_accordion ${openAccordion === index ? 'open' : ''}`} key={index}>
                            <div className="accordion_heading" onClick={() => handleAccordionClick(index)}>
                                <span>{item.title}</span> <img src={PlusIcon} className="chevron_down" />
                            </div>
                            <div className="accordion_content">
                                <p>{item.content}</p>
                            </div> 
                        </div>
                    ))}
                </div>
                {showMoreFAQ == false && (
                    <div className="show_more_btn" onClick={()=> {setShowMoreFAQ(true)}}>
                        Show more <img src={ChevronDown} className="chevron_down" />
                    </div>
                )}
                {showMoreFAQ && (
                    <div className="faq_section">
                        <h3>MORE</h3>
                        {faqData2.map((item, index) => (
                            <div className={`single_accordion ${openAccordion === index ? 'open' : ''}`} key={index}>
                                <div className="accordion_heading" onClick={() => handleAccordionClick(index)}>
                                    <span>{item.title}</span> <img src={PlusIcon} className="chevron_down" />
                                </div>
                                <div className="accordion_content">
                                    <p>{item.content}</p>
                                </div> 
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <img src={PricingWaves} className='pricing_waves' />
        </div>
    )
}