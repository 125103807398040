import React from 'react';
import { Link } from 'react-router-dom';

/*------------------| Images |------------------*/
import VerifiedIcon   from '../../assets/images/icons/verfified_orange.svg'; 
import Company_badge_orange from '../../assets/images/icons/orange_badge.svg';
import client_image from '../../assets/images/logos/employers/coin-call-excange.png';
import { ReactComponent as FacebookIcon } from '../../assets/images/social_media/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../assets/images/social_media/instagram.svg';
import { ReactComponent as TwitterIcon } from '../../assets/images/social_media/twitter.svg';


export default function CoinCallExchange() {  
    return (
        <div className="employer_page">
            <div className="employer_container">
                <div className="employer_top">
                    <div className="employer_img"> 
                        <img src={client_image} />
                    </div>
                    <div className="employer_details">
                        <div className="employer_name">
                            <img src={Company_badge_orange} /> <h2>Coincall Exchange</h2> <img src={VerifiedIcon} />
                        </div>
                        <div className="employer_links">
                            <a href="http://www.coincall.com">http://www.coincall.com</a>
                            <div className="social_links">
                                <a href="#"><FacebookIcon /></a>
                                <a href="#"><InstagramIcon /></a>
                                <a href="#"><TwitterIcon /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="employer_bottom">
                    <div className="employer_left"> 
                        <div className="employer_summary_stats">
                            <div className="single_summary">
                                <strong>01/02/2022</strong> <span>Founded Date</span>
                            </div>
                            <div className="single_summary">
                                <strong>0</strong> <span>Posted Jobs</span>
                            </div>
                        </div> 
                        <div className="title_with_links">
                            <h3>Company Sectors</h3>
                            <div className="company_links_container">
                                <a href="#">Cryptocurrency</a>
                            </div>
                        </div>
                        <div className="title_with_links">
                            <h3>HQ Location</h3>
                            <div className="company_links_container">
                                <a href="#">United Kingdom</a>
                            </div>
                        </div>
                    </div>
                    <div className="employer_right">
                        <div className="company_description">
                            <h3>Company Description</h3>
                            <p>We are a startup derivatives exchange that focuses on options trading, with an obsession for ease of use from day one, catering to both new and professional traders.</p>

                            <p>The crypto industry is quickly evolving, and while crypto options are growing, it remains relatively small compared to the size of the overall crypto market. Our experience in TradFi tells us that crypto options trading is still in its early stages of growth. This presents us with an opportunity to offer something different from the already crowded crypto exchange market.</p>
                        </div>
                        <div className="work_here">
                            <h2>I wanna work here</h2>
                            <form action="">
                                {/*-------| I think this better be a <select> but not sure which options should it have |------*/}
                                <input type="text"  name='position' placeholder='Position'/>
                                <input type="email" name="email" placeholder='Email' />
                                <button type="submit">Send Request</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ready_to_work">
                <h2>Ready to work with Metamask?</h2>
                <a href="#" className="sign_up_btn">Sign Up</a>
                <p>Already have an account? <a href="#">Log in</a></p>
            </div>
        </div>
    )
}